<template>
  <revenue
    :loading="loading"
    :chart-options="getChartOptions"
    :current-user-context="currentUserContext"
    :can-refetch="canRefetch"
    @refetch="refetch"
  />
</template>

<script>
import Revenue from './Revenue'
import th from '@tillhub/javascript-sdk'

export default {
  name: 'RevenuePerDay',
  components: {
    Revenue
  },
  props: {
    currentUserContext: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    return {
      revenuePerDay: [],
      loading: false,
      canRefetch: false
    }
  },
  computed: {
    selectedDates() {
      return this.currentUserContext.selectedDates
    },
    resourceQuery() {
      // global branch filter overrides local branch filter
      let branchNumber
      if (Number.isFinite(this.currentUserContext.currentBranchNumber)) {
        branchNumber = this.currentUserContext.currentBranchNumber
      }

      const query = {
        location: this.currentUserContext.currentLocation || undefined,
        branch_number: branchNumber,
        start: this.selectedDates.start,
        end: this.selectedDates.end
      }

      return query
    },
    getChartOptions() {
      return {
        title: this.$t('pages.home.widgets.revenue_per_day.title'),
        xAxis: {
          name: this.$t('pages.reports.statistics.overview.day')
        },
        series: [
          {
            name: this.$t('pages.reports.statistics.overview.turnover'),
            data: this.revenuePerDay,
            type: 'bar'
          }
        ]
      }
    }
  },
  watch: {
    selectedDates(value) {
      this.updateRevenuePerDay()
    }
  },
  mounted() {
    this.updateRevenuePerDay()
    this.$emitter.on('refresh-requested', () => {
      this.refresh()
    })
  },
  beforeUnmount() {
    this.$emitter.off('refresh-requested')
  },
  methods: {
    async updateRevenuePerDay() {
      if (!this.selectedDates.start || !this.selectedDates.end) {
        this.revenuePerDay = undefined
        return
      }
      const { selectedCurrency } = this.currentUserContext

      this.loading = true
      try {
        const resp = await th
          .analytics({ timeout: 90000 })
          .getRevenues(this.resourceQuery)

        this.revenuePerDay = resp.data[0].values
          .map((item) => [
            item[0],
            item[1].filter(({ currency }) => currency === selectedCurrency)
          ])
          .map((point) => {
            const iso = point[0]
            const date = this.$date.formatDate(iso)
            const amount = point[1][0]?.amount
            return [date, amount]
          })
      } catch (e) {
        this.canRefetch = true
        this.revenuePerDay = []
      } finally {
        this.loading = false
      }
    },
    refresh() {
      this.$nextTick(() => {
        this.refetch()
      })
    },
    refetch() {
      this.updateRevenuePerDay()
      this.canRefetch = false
    }
  }
}
</script>
